@import '../variables';

.result-card-percent-change{
  justify-content: center;
  display:flex;
  align-items: center;
}

.select-result-card-text{
  font-weight:500 !important;
  font-size:1.0rem !important;
}

.select-result-card{
  margin-bottom:10px;
  padding: 10px 15px 10px 15px;
  
  .positivity-indicator{
      font-size:1.2em;
      font-weight:600;
  }
}

.answers-title-row{
  height: 45px;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 15px;
}

.answers-card-titles-row{
  justify-content: center;
  display:flex;
  font-weight:700;
  span{
      text-align: center;
  }
}

.answers-card-titles-row-container{
  padding-left:16px;
  padding-right:16px;
}


@media screen and (max-width: 767px) {
  .result-card-change-col{
    justify-content: space-between !important;
    span{
        font-size:14px !important;
    }
  }
  .result-card-percent-change{
      justify-content: flex-end !important;
  }
  .result-card-scoreunit{
      padding-right:15px;
      font-size:12px !important;
  }
}