.result-card{
  box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08);
  background-color:white;
  border-radius:8px;

  .result-card-title{
    background-color: #f4f7fc;
    font-size:14px;
    padding:13px;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  table{
    border-radius: 8px;
  }

  table thead tr{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  table thead tr th:first-child{
    border-top-left-radius: 8px;
  }

  table thead tr th:last-child{
    border-top-right-radius: 8px;
  }

  table tbody tr:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 8px;
  }

  table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 8px;
  }

  table tbody tr td:first-child{
    padding-left:20px;
    width:280px;
  }

  table tbody tr td:last-child{
    padding-right:20px;
  }
}