.superadmin-header {
  background-color: white;
  padding-top: 30px;
  padding-left: 40px;
  padding-bottom: 30px;

  h3{
    font-weight: bold;
    margin-bottom:0px;
  }
}

.super-admin-iconbutton{
  font-size: 24px;
}
.create-organization-card{
  width: 350px;
}

.return-icon{
  font-size: 50px;
}

.organization-settings-card{
  min-width: 350px;
}

.change-password-card{
  width: 400px;
}