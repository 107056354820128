.questionnaire-reminder-modal{
  max-width:max-content;
  min-width: 840px;

  .table > tbody > tr > td {
    font-weight: 500
  }

  .modal-body{
    h5{
      font-size:18px;
      font-weight:500
    }
  }
  
  
  .fa-calendar{
    font-size:14px;
    padding-right:5px;
  }

  .alert{
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .reminder-table-container{
    max-height: calc(100vh - 400px);
    overflow: auto;
  }

  .selectedTableRow{
    background-color: #f4f7fc;
  }

  .mass-selection-item{
    width:max-content;
    padding:0px 10px;
    span{
      font-size:14px;
    }
  }
  .mass-selection-toggle{
    padding:8px 16px;
  }
  .mass-selection-dropdown .dropdown-menu{
    border: 1px solid black;
    padding: 12px;

    button{
      padding:8px 16px;
    }
  }
}

.questionnaire-reminder-modal.modal-dialog{
  padding-top:20px;
}

.reminder-modal-questionnaire-text{
  font-size:18px;
  margin-bottom:0;
  font-weight:500
}

.reminded-dropdown{
  button {
    background-color:transparent !important;
    color: black !important;
    font-size: 14px;
    font-weight: 500 !important;
    opacity: 1 !important;
    border-color:transparent;
    box-Shadow:none !important;
    padding: 4px 0px;
  }

  .dropdown-menu{
    button{
      padding: 0.375rem 0.75rem;
    }
  }
}

.reminder-input{
  display:initial;
  width: 60px;
  margin-left:5px;
  margin-right:5px;
}

.all-answer-request-answered-text{
  font-size:15px;
}