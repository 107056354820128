.email-example{
  align-self: flex-start;
  h4{
    font-weight: 600;
    font-size: 20px;
    margin:0;
  }

  h5{
    font-weight: 700;
    font-size: 1.57em;
  }

  .btn{
    background-color: rgb(251, 186, 24);
    color:black;
    border-radius: 12px;
    text-transform: none;
    font-size:16px;
    font-weight:600;
    padding-right:44px;
    padding-left:44px;
    padding-top:13px;
    padding-bottom:13px;
    margin-bottom:15px;
    cursor:not-allowed !important;
  }
}


.email-small-description{
  font-weight:600;
  font-size:12px;
  margin-bottom:0;
}

.footer-item{
  background-color: black;
  color:white;
  width:33.33333%;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size:12px;
}