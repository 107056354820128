.service-user-view {
	display: flex;
	align-items: baseline;
	flex-direction: column;

	.client-notes-card {
		width:100%;
	}
	
	.client-questionnaires-card{
		width:100%;
	}
}

@media screen and (min-width: 1400px) {
	.service-user-view {
		flex-direction: row;

		.client-questionnaires-card{
			width:55%
		}
		.client-notes-card{
			width:45%
		}
	}
}
