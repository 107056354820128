.new-service-questionnaire-info-modal{
  width:500px !important;
  max-height: 100% !important;
  text-align: center;

  .modal-body{
    padding:17px;
  }

  .lead{
    color:black !important;
  }

  .or-text{
    font-weight:500;
    margin-bottom: 0;
    text-align: center;
  }

  span{
    font-weight:400;
    font-size:14px;
  }

  hr{
    border-top:2px solid lightgray;
    margin-top:8px;
    margin-bottom:8px;
  }

  h4{
    font-size:24px;
    font-weight:800;
  }

  h5{
    font-size:22px; 
    font-weight:800;
  }

  img{
    width:135px;
    height:135px;
  }
}

.new-service-questionnaire-info-modal > p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.new-service-questionnaire-info-box{
  margin-bottom:5px;
  padding:10px;
  background-color: #E2F4D9;
  border-radius: 28px;
  text-align: left;

  li{
    text-align: left;
    font-weight:700;
    font-size:16px;
  }

  ul{
    margin-bottom:0px;
  }
}