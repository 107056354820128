@import './variables';

.questionnaireTab-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
}

.questionnaires-count-badge {
  background-color: $xamk-blue;
  border-radius: 5px;
  width: 45px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  margin-bottom: 8px;
}

.questionanires-count-text {
  font-size: 18px;
}

.card-titles-row {
  margin-right: 0.5em;
  max-width: 1200px;
  justify-content: end;

  p {
    width: 60px;
  }
}

.header-texts {
  margin: 0;
  padding: 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    ;
    text-align: center;
    padding: 0;
  }

  p {
    max-width: 90%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}

.name-column {
  flex: 0 0 65%;
  max-width: 65%;
}

.questionnaireTab-titles-row {
  max-width: 1200px;
  flex-basis: auto;
}

//QuestionnaireCard
.QuestionnaireCard {
  max-width: 1200px;
  border-radius: 17px;
  margin-bottom: 30px;

  .card-footer {
    border-top: 1px solid black;
    background-color: #F9FEFF;
    border-radius: 0px 0px 17px 17px;
  }
}

.questionnaire-name-text {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2.5rem;
}

.analytics-button-text {
  text-decoration: underline;
  color: $xamk-blue;
  font-size: 18px;
}

.interval-text {
  font-size: 12px;
}

.popover {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  padding: 0.25em;
}

.popover-header-text {
  text-align: center;
  text-transform: none;
  margin-top: 0.5em;
  margin-bottom: 0px;
}

.anonymous-link-icon {
  font-size: 1.15rem;
  vertical-align: bottom;
  margin-left: 4px;
}

.answer-badge {
  color: white;
  font-size: 12px;
  border-radius: 8px;
  padding: 1px 0.5em;
  margin-left: 5px;
}

.answer-badge-client {
  background-color: #0ED145;
}

.answer-badge-professional {
  background-color: #00A8F3;
}

.answer-badge-anonymic {
  background-color: #000000;
}

// Anonymic answer info modal
.anon-copy-input {
  cursor: text !important;
  max-width: 465px;
  font-size: 12px;
}

.shareable-link-copy-input {
  cursor: text !important;
  max-width: 465px;
  font-size: 12px;
}

//Questionnaire Settings
.questionnaire-settings-modal hr {
  border-top: 1px solid black;
}

.questionnaire-settings-switch-label {
  padding-top: 3px;
  font-size: 16px;
}

.setting-switch-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.questionnaire-settings-switch-description {
  text-align: right;
  color: gray;
  width: 450px;
  padding-top: 0.20em;
  font-size: 12px;
}

.settings-check-radio label {
  color: black;
}

.settings-input-text {
  margin-left: 10px;
}

.settings-button {
  min-width: 110px;
}


.interval-card {
  box-shadow: 0 0;
  border: 0;
  padding: 1.5em;
}

@media screen and (min-width: 1200px) {
  .questionnaire-settings-modal {
    max-width: 1000px;
  }
}

//New Questionnaire modal
.accordion {
  .card-header {
    padding: 10px;
    border: 1px solid $xamk-blue;
    cursor: pointer;
  }

  .card-body {
    border: 1px solid $xamk-blue;
  }

  .card-header.selected {
    color: white;
    background-color: $xamk-blue;
    transition: 0.3s;
  }
}

.accordion.card {
  margin-bottom: 10px;
}

.add-questionnaire-form {
  border: 1px solid black;
  border-radius: 10px;
}

.search-word-input {
  width: 250px;
}

.badge-xamk {
  border-color: $xamk-blue;
  background-color: $xamk-blue;
}

.new-questionnaire-button {
  width: 125px;
}

@media screen and (min-width: 1200px) {
  .new-questionnaire-modal {
    max-width: 1000px;
  }
}

@media screen and (max-width: 576px) {
  .new-questionnaire-content {
    .modal-body {
      padding: 10px 0px;
    }
  }
}