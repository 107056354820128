.answerAnalytics-header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-right:30px;
  padding-bottom:20px;
  
  h4{
    margin: 0;
    font-weight: 700;
  }

  h5{
    text-align: right;
    font-size:18px;
    margin:0;
  }

  h6{
    font-size:16px;
    text-transform: none;
    font-weight:500;
    text-align: right;
  }
}

.answerAnalytics-table{
  width:auto;
  tbody{
    width: 20%;
    overflow: scroll; 
  }
  th{
    overflow: auto;
    padding-right: 15px !important;
  }
  thead{
    tr{
      th{
        border: 1px solid #dee2e6;
      }
    }
  }
}

.questionColumn{
  max-width:800px;
  margin-left:5px;
}

.answerAnalytics-header-text{
  font-weight:600;
}

.column-header-name{
  width:150px;
}

.column-header-question{
  width: 300px;
}

.baseInfoColumn{
  margin-left:5px;
}

.otherInfoColumn{
  margin-left:5px;
}

.answerTable-container{
  overflow-x: auto;
  background-color: white;
}

.questionGroup-title{
  font-weight:600;
  font-size:14px;
}

.column-selector-card{
  max-height:400px;
  overflow: auto;
}

.add-filter-button{
  border-radius: 100%;
  padding: 9px 9px;

  svg{
    font-size:15px
  }
}

.addFilter-modal{
  max-width:1000px;
}

.filter-card{
  .card-body{
    padding:9px 5px 9px 15px
  }
  font-size:14px;
  font-weight:400;
}

.filters-container{
  border-left: 1px solid rgb(172, 172, 172);
}

.answerAnalytics-questionnairepreview-button{
  height:22px !important;
  i{
    font-size:17px;
    padding-right:5px;
  }
}

.answerAnalytics-daterange-toggle{
  background-color: white;
}

.remove-filter-button{
  padding-bottom:1px;
  padding-top:0px;
}

.answerAnalytics-copyclipboard-button{
  float: right;
  padding:2px;
  i{
    font-size:15px;
    color:$xamk-blue;
  }
}

.answerAnalytics-back-icon{
  font-size: 44px;
  color:black;
}