.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  font-weight:600;
  font-size:14px;
  font-family: monospace;
  color:black;
}

.btn-breadcrumb{
  margin-bottom:0;
  margin-top:0;
  padding:0;
  color: $xamk-blue !important;
  text-transform: none;
  font-size:13px;
}

.breadcrumb{
  background-color: transparent;
  padding:0;
  margin-bottom:0;
}