.xamk-blue {
  color: $xamk-blue;
}

.xamk-yellow {
  color: $xamk-yellow;
}

.yellowunderline{
  text-decoration: underline solid #FBBA18 0.4rem;
  text-underline-offset: -0.1rem;
  text-decoration-skip-ink: none;
}

.cursor-pointer{
  cursor: pointer;
}

.subheader1 {
  font-size: 1.15rem;
  font-weight: 500;
}

.fw-500{
  font-weight: 500;
}

.table-rounded{
  border-radius: 12px;

  thead tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  
  thead tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
  }
  
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
  }

  tbody:last-child{
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  thead tr:first-child{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}


//These are for hiding arrow buttons in number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-xs	{
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
.text-sm{
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.text-base{
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
.text-lg{
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.text-xl{	
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.text-2xl{	
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.text-3xl{	
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
.text-4xl{	
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}
.text-5xl{	
  font-size: 3rem; /* 48px */
  line-height: 1;
}
.text-6xl	{
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}
.text-7xl	{
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}
.text-8xl{	
  font-size: 6rem; /* 96px */
  line-height: 1;
}
.text-9xl{	
  font-size: 8rem; /* 128px */
  line-height: 1;
}

.font-thin{	font-weight: 100;}
.font-extralight{	font-weight: 200;}
.font-light{	font-weight: 300;}
.font-normal{	font-weight: 400;}
.font-medium{	font-weight: 500;}
.font-semibold{	font-weight: 600;}
.font-bold{	font-weight: 700;}
.font-extrabold{ font-weight: 800;}
.font-black{ font-weight: 900;}

