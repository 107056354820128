.button-list-table{
  tbody{
    tr{
      background-color: $xamk-blue;
      color:white;
      cursor: pointer;
      th{
        border:0;
      }
    }

    tr.disabled{
      opacity: .5;
      pointer-events: none;
      cursor:not-allowed;
    }

    tr:hover td {
      background-color: #545b62;
    }
  }  
}

.table.button-list-table > thead > tr > th, .table.button-list-table > tbody > tr > th, .table.button-list-table > tfoot > tr > th, .table.button-list-table > thead > tr > td, .table.button-list-table > tbody > tr > td, .table.button-list-table > tfoot > tr > td {
  padding:12px 15px;
}