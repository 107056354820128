.analytics-box.card {
  width: 650px;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 200px;
  margin-bottom: 25px;

  h3 {
    text-align: center;
    margin-bottom: 2px;
    font-weight: bold;
  }  

  .fa {
    color: $xamk-blue;
  }  

  svg {
    color: $xamk-blue;
  } 

  .card-subtitle {
    text-align: center;
    margin-bottom: 2px;
    margin-top: unset;
    text-transform: unset;
  }

  .score-unit {
    margin-top: -13px;
    // margin-bottom: 5px;
  }
}

.analytics-header {
  background-color: white;
  padding-top: 30px;
  padding-left: 40px;

  h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .nav {
    justify-content: left;
  }  

  .nav-title {
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav-link {
    cursor: pointer
  }

  .nav svg {
    color: $xamk-blue;
  }

  .nav-item.active {
    border-bottom: 2px solid $xamk-blue;
  }

  .statistic-value {
    font-size: 15pt;
    font-weight: bold;
  }

  .btn {
    text-transform: none;
    font-size: 13px;
  }
}

.analytics-language{
  i{
    font-size: 22px;
    margin-top: 4px;
  }

  select{
    width: 67px;
    font-size: 15px;
    font-weight: 700;
    color: blacK;
    background-color: transparent;
    border: 0;
  }
}

.analytics-content {
  padding: 40px;
  background-color: $content-background-color;

  .ReactTable {

    .rt-th {
      text-transform: unset;
      padding: 5px;
      white-space: normal !important;
    }

    .rt-td {
      border: none;
      border-top: 1px solid rgb(240, 240, 240);
      padding: 5px;
    }

    .rt-table {
      border: none;
    }

    .rt-tfoot {
      box-shadow: none;
    }
  }  

  .table {
    th {
      text-transform: unset;
    }
  }
}

.analytics-navigation {
  width: 600px;

  .list-group-item-action{
    color:black;
  }
}

@media screen and (max-width: 900px) {
  .analytics-navigation {
    width: 100%;
  }
}

.list-group-header {
  font-size: 20px;
  font-weight: 500;
  color:black;
}

.list-group-text {
  font-size: 14px;
  font-weight: 400;
}

.analytics-table {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 13px;
}