.service-questionnaire-settings{
  padding-top: 20px !important;
  max-width: 1050px;

  .table th, .table td{
    border-top:0;
    border-bottom:1px solid #dee2e6;
  }

  thead tr th{
    font-weight: 600;
  }

  hr{
    border-top:1px solid rgba(0, 0, 0, 0.7);
  }

  .toggle-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
  }
}

.interval-example-box{
  margin-left:40px;
  background-color: #E5E9FF;
  padding:30px;
  border-radius: 11px;
  align-self:flex-start;
}