.user-dropdown {
  text-align: center;
  margin-top: 20px;

  & > .dropdown-menu {
    transform: unset
  }

  .user-dropdown-toggle {
    background: none;
    color: #4e5255;

    & .user-name {
      display: inline-block;
      margin-left: 15px;
      cursor: pointer;
      max-width: 110px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
      user-select: none;
    }
  }
}

.dropdown-item {
  cursor: pointer;
  font-size: 10pt;
  user-select: none;
}

.dropdown-item .disabled {
  color: gray;
  cursor: default;
}

