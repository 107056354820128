.service-collaborator-logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;

  img {
    padding: 8px;
    object-fit: contain;
    max-height: 75px;
    height: 75px;
  }
}