.unit-selector{
  flex-direction: row;

  button{
    width:180px;
    text-align: center;
    padding:6px 3px;
    border: 1px solid black !important;
    box-shadow: none;
    border-radius: 0px !important;
    font-size:13px;
  }

  .list-group-item.active{
    background-color: #76bcd5 !important;
    color:black;
  }
}