.navbar-clientdashboard {
  background-color: $xamk-yellow;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.40);

  button {
    background-color: transparent;
    color: black;

    &:hover {
      background-color: transparent;
      color: white;
    }

  }

  .nav {
    background-color: $xamk-yellow;
  }

  .navbar-collapse {
    z-index: 5;
  }

  .navbar-brand {
    img {
      width: 90px;
    }
  }

  &.card {
    box-shadow: 0 0 0 0;
  }

  .messages-navbutton:hover {
    strong {
      color: black !important;
    }
  }

  .icon-stack {

    margin-left:2px;
    position: relative; /* Enable stacking */
    display: inline-block;
    width: fit-content;
    height: fit-content;
  }
  
  .icon-background {    
    top: 0;
    left: 0;
  }
  
  .icon-foreground {
    color: white;
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); /* Offset for perfect centering */
  }

  @media screen and (max-width: 767px) {
    .pl-4 {
      padding-left: 0px !important;
      margin-left: -25px;
    }

    i {
      font-size: 18px;
    }

    .nc-icon {
      font-size: 18px;
    }

    Button {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 991px) {
    .pl-4 {
      padding-left: 0px !important;
    }
  }
}