.splash-box {
  min-width: 150px;
  width: min-content;
  height: auto;

  &>.progress-bar {
    background-color: lightgrey;
    height: 2px;
    width: 100%;

    &>.filling {
      background-color: $xamk-blue;
      height: 100%;
    }
  }

  .client-progress-bar {
    display: none;
  }

  &>.spinner {
    width: 20px;
  }
}