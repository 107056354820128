.wrapper-anonymous{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center, center;
  display:flex;
  flex-direction: column;

  .btn-primary{
    background-color:$xamk-yellow;
    color:black;
  }

  .lang-dropdown-navbar > .dropdown-toggle {
    margin: 0;
  }
}

@media print {
  body, html, .wrapper-anonymous {
    height: 100% !important;
    width: 100% !important;
    display: block;
  }
  #navbar-main, .footer-anon, .btn-round, .btn-primary, .btn-lg {
    display: none;
  }
}

.anon-container{
  max-width: 900px;
}

.navbar-anonymous{
  display:flex;
  justify-content: space-around;
  align-items: center;
  background-color: $xamk-yellow;
  padding:15px;
  img{
    max-height:40px;
  }
  span{
    margin:0;
    padding:0;
    font-size:18px;
    font-weight: 700;
  }
  i{
    font-size:30px;
    margin-bottom:3px;
  }

  box-shadow: 0 0 24px rgba(0, 0, 0 , 0.40);

  select{
    width:70px;
    font-size: 18px;
    font-weight: 700;
    color:blacK;
    background-color:transparent;
    border: 0;
  }
}

.container-anonymous{
  max-width: 1700px;

  .btn-round{
    color:white;
    background-color:black;
    &:hover{
      background-color:$xamk-yellow;
      color:white;
    }
  }
}

.anon2{
  max-width:800px;
  margin-top: 1.5rem;
}


.footer-anon{
  margin-top:auto;
  background-color:black;
  padding:20px;

  img{
    height:50px;
  }
  b{
    color:white;
  }
  a{
    color:white;
  }
  span{
    color:white;
  }
}

.subheader2 {
  font-size: 18px;
  font-weight: 700;
}

.headertext1{
  font-size:2.5em;
  font-weight:500
}

.subtext2{
  font-size:16px;
  font-weight:500;
}

.subtext3{
  font-size:16px;
  font-weight:500
}

.subtext4{
  font-size:18px;
  font-weight:500;
}


@media screen and (min-width: 600px) {
  .subheader2 {
    font-size: 1.10rem;
    font-weight: 700;
  }
  .subtext2{
    font-weight:500;
  }
  .subtext4{
    font-size:1.57em;
    font-weight:500;
  }
}


@media screen and (min-width: 600px) {
  .navbar-anonymous{
    justify-content: center;

    span{
      padding-left:50px;
      font-size: 22px;
      //font-weight:
    }
  }

  .logo-column{
    justify-content: center !important;
    
  }
  .logo-column > img {
    margin-left: 20px;
    margin-right: 20px;
  }
}

