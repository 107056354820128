.client-list-card{
  max-width:700px;
  margin-bottom:0px;
  padding:10px 15px 10px 15px;
  border-radius:0px !important;
  
}

.client-list-card:hover{
  z-index: 1;
  transform: scale(1.01);
}

.client-card-container{
  .name-text{
    font-size:15px;
    font-weight:500;
    color:black;
  }

  .email-text{
    font-size:13px;
    font-weight: 500;
    color:black;
  }
}

.analytic-cards-container{
  max-width:700px;

  
}

.client-list-container{
  max-width:1100px;
}

.changeUserIcon{
  width:40px;
}

.search-input-box{
  padding:12px;
  background-color: $xamk-blue;
  color:white;
  border-radius:4px 0px 0px 4px;
}

.icon-button{
  border-radius: 100px !important;
  background-color: $xamk-blue;
  padding:8px 10px;
  svg{
    width:25px;
  }
}

.suq-container{
  max-width:900px;
  .card-body{
    padding:30px;

  }
}


.client-analytics-tab{
  

  .card{
    border:0px !important;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.125) 
  }

  .clickable-card {
    border:0px !important;
  }

  .clickable-card{
    cursor: pointer;
    .text-muted{
      font-size:14px;
      font-weight:400;
    }

    span{
      font-size:16px;
      font-weight: 500;
    }
  }

  .clickable-card:hover{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.30);
    transform: scale(1.01);
  }

  .category-icon{
    width:45px;
  }

  .clickable-card-small{
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.125)
  }

  .clickable-card-small:hover{
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.30)
  }

}

.client-analytics-header-text{
  font-size:22px;
  font-weight:600;
  margin-left:10px;
}

.client-switch{
  margin-right:5px;
  display:flex;
  align-items: flex-end;
  cursor: pointer;
  flex-direction: column;
}

.client-switch:hover{
  span{
    text-decoration: underline;
  }
}

.client-switch-name-text{
  font-size:16px;
  font-weight:700;
}

.client-switch-change-text{
  font-size:11px;
  font-weight:500;
}

.serviceuserquestionnaire-analytics-title-group{
  h5{
    font-size:17px;
    font-weight:600;
    margin-bottom:0;
  } 
}

.serviceuserquestionnaire-analytics-title-group:hover{
  h5, p{
    text-decoration: underline;
  }
}
