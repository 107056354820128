.new-sidebar {
  background-color: #ffffff;
  width: 240px;
  position: fixed;
  height: 100%;
  border-right: 1px solid $content-background-color;
  z-index: 1;

  .new-sidebar-menu {
    overflow: auto;
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);    
    padding-right: 10px;
  }

  .new-sidebar-bottom {
    border-top: 1px solid $content-background-color;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    height: 50px;
    width: 100%;
  }
}

.new-sidebar-item {
  margin-left: 40px;
  // margin-bottom: 20px;
  margin-top: 20px;

  color: #4e5255;

  i {
    color: #4e5255;
    padding-top: 3px;
  }  

  a {
    display: flex;
  }

  .new-sidebar-icon {
    order: -1;
    color: #4e5255;
    margin-top: 2px;
    width: 15px;
    height: 15px;
  }

  span.new-sidebar-item-title {
    color: #4e5255;
    margin-left: 10px;
    vertical-align: top;
    user-select: none;
  }

  span.new-sidebar-item-badge {
    font-size: 8pt;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 2px;
    margin-left: auto;
    margin-right: 12px;
    height: 15px;
  }

  &.active {
    i {
      color: #007daa;
    }  

    span.new-sidebar-item-title {
      color: black;
      font-weight: bold;
    }

    span.new-sidebar-item-badge {
      background: $xamk-blue;
      color: white;
      //font-weight: bold;
    }

    .new-sidebar-icon {
      color: $xamk-blue;
    }
  }
}



.new-sidebar-subitem {
  margin-left: 30px;
  // margin-top: 10px;
  color: #4e5255;
  padding: 10px;
  padding-left: 40px;
  border-radius: 10px;
  font-size: 9pt;
  user-select: none;

  .new-sidebar-subitem-title {
    color: #4e5255;
  }

  &.active {
    background: #eaf8fb;

    .new-sidebar-subitem-title {
      font-weight: bold;
      color: black;
    }
  }

  &:first-child {
    margin-top: 10px;
  }

  .subitem-icon {
    color: $xamk-blue;
    margin-left: -14px;
  }
}

.new-sidebar-item-badge {
  background: grey;
  color: white;
  border-radius: 4px;
}

.new-sidebar-top-logo {
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left:50px;
  padding-right:30px;
}

.new-sidebar-preview-button {
  margin:-3px 1px 0px 0px;
}

.logo img {
  height: 40px;
  margin-left: 30px;
}

.sidebar-organization-name{
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding-top: 5px;
}

@media screen and (max-width: 500px) {
  .new-sidebar {
    display: none;
  }
}

.sidebar-open {
  .new-sidebar {
    display: block;
  }

  .new-sidebar-top-logo {
    padding-top: 0px;
  }

  .new-sidebar-menu {
    max-height: calc(100vh - 130px);
  }
}

.sidebar-toggler {
  display: none;
  color: $xamk-blue;
  background: none;
}

@media screen and (max-width: 500px) {
  .sidebar-toggler {
    display: block;
  }
}

