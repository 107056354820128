.footer{
    padding: 24px 0;
    background-color: #f4f7fc;

    &.footer-default{
        background-color: #f2f2f2;
    }

    &.footer-white{
        color: $white-color;
    }

    &.footer-client{
        color: black;
        background-color: transparent;
    }

    nav{
        display: inline-block;
        float: left;
        padding-left: 0;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
        line-height: 1.8;
        margin-right: 10px;
    }

    .copyrightclient{
        font-size: $font-size-small;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }

    .clientfooterlogos{
        float: left;
        width: 44px;
        height: 44px;
        margin-right: 5px;
        margin-left:5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}