// These override the default paper-dashboard styles.

@import 'hyvio/variables';
@import 'hyvio/common';
@import 'hyvio/new-sidebar';
@import 'hyvio/dropdown';
@import 'hyvio/footer';
@import 'hyvio/questionnaireTab';
@import 'hyvio/questionnaireForm';
@import 'hyvio/splashScreen';
@import 'hyvio/superAdmin';
@import 'hyvio/anonymous';
@import 'hyvio/analytics';
@import 'hyvio/serviceQuestionnaireReminderModal';
@import 'hyvio/clientDashboard/clientDashboard';
@import 'hyvio/clientDashboard/clientAnalytics';
@import 'hyvio/clientDashboard/answerTimeList';
@import 'hyvio/clientDashboard/navBar';
@import 'hyvio/analyticsClientTab';
@import 'hyvio/joinPage';
@import 'hyvio/answerAnalytics';
@import 'hyvio/buttonListTable';
@import 'hyvio/emailExample';
@import 'hyvio/serviceQuestionnaireSettings';
@import 'hyvio/breadcrumb';
@import '/src/dashboard/components/modals/NewServiceQuestionnaireInfoModal';
@import '/src/components/ServiceCollaboratorLogoList.scss';
@import '/src/dashboard/views/Analytics/AnalyzeTab/AnalyzeQuestionnaireTab/QuestionTab/QuestionTab.scss';
@import '/src/dashboard/views/ServiceCollaborators/ServiceCollaboratorExample.scss';
@import '/src/dashboard/views/ServiceUserView/ServiceUserView.scss';
@import '/src/dashboard/views/Analytics/AnalyzeTab/AnalyzeQuestionnaireTab/QuestionTab/UnitSelector.scss';
@import '/src/dashboard/views/Analytics/AnalyzeTab/AnalyzeQuestionnaireTab/OverviewTab/resultCard.scss';
@import '/src/clientDashboard/components/Analytics/QuestionReport.scss';

.basic-header {
  background-color: white;
  padding-left: 45px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 30px;

  h3 {
    margin: 0;
    font-size: 2em;
    font-weight: 700;
  }

  h4 {
    margin: 0;
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
    margin: 0;
  }

  .nav {
    justify-content: left;
  }

  .nav-title {
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav svg {
    color: $xamk-blue;
  }

  .nav-item.active {
    border-bottom: 2px solid $xamk-blue;

    a {
      color: black;
    }
  }

  .statistic-value {
    font-size: 15pt;
    font-weight: bold;
  }

  .nav-link {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
  }

  .nav-tabs {
    margin-top: 15px;
  }

  .nav-text {
    margin-left: 10px;
    margin-bottom: 1px;
  }
}

.basic-content {
  min-height: 67vh;
  padding: 40px;

  .card {
    h3 {
      font-weight: 600;
      font-size: 1.8em;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 1.35em;
      font-weight: 700;
    }
  }
}

.table-card {
  .card-header {
    padding: 20px 20px 0px;
  }

  .card-body {
    padding: 20px 20px 15px 20px
  }

  h5 {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 22px;
  }
}

.modal-base-title {
  font-weight: 600;
  align-self: center;
}

.service-box {
  width: 300px;
  padding-left: 30px;
  padding-right: 30px;

  h3 {
    text-align: center;
    margin-bottom: 2px;
    font-weight: bold;
  }

  .fa {
    color: $xamk-blue;
  }

  svg {
    color: $xamk-blue;
  }

  .card-subtitle {
    text-align: center;
    margin-bottom: 2px;
    text-transform: unset;
  }
}

.service-header {
  background-color: white;
  padding-top: 30px;
  padding-left: 40px;

  h3 {
    font-weight: bold;
    margin-bottom: 0px;
  }

  .nav {
    justify-content: left;
  }

  .nav-title {
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav svg {
    color: $xamk-blue;
  }

  .nav-item.active {
    border-bottom: 2px solid $xamk-blue;
  }

  .statistic-value {
    font-size: 15pt;
    font-weight: bold;
  }

  .nav-link {
    cursor: pointer
  }
}

.service-content {
  min-height: 67vh;
  padding: 40px;
  background-color: $content-background-color;

  .ReactTable {

    .rt-th {
      text-transform: unset;
      padding: 5px;
    }

    .rt-td {
      border: none;
      border-top: 1px solid rgb(240, 240, 240);
      padding: 5px;
    }

    .rt-table {
      border: none;
    }

    .rt-tfoot {
      box-shadow: none;
    }
  }
}

.main-panel .new-layout-content {
  padding: 0;
  margin: 0;
  //padding-top: 62px;
}

.main-panel {
  width: calc(100% - 240px);
}

.circle {
  background-color: $xamk-blue;
  color: white;
  border-width: 1px;
  border-radius: 50%;
  padding-right: 11px;
  padding-left: 11px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.message-instructions-text {
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.textarea-autosize {
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #66615b;
  line-height: 1.6;
  font-size: 14px;
  width: 100%;
}

.textarea-autosize:focus {
  border: 1px solid #9A9A9A;
  box-shadow: none;
  outline: 0;
  color: #66615B;
}

.input-button {
  position: absolute;
  top: 60%;
  right: 3%;
  font-size: 1.20em;
}

.input-title {
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.input-checkbox {
  transform: scale(1.3);
  margin-top: 5px !important;
}

.input-button:hover {
  color: $xamk-blue;
  cursor: pointer;
}

.checkbox-title {
  font-size: 14px;
  font-weight: 500;
}

.change-password-modal {
  max-width: 500px;
}

.change-answers-modal {
  max-width: 1000px;
}

.newServiceUserQuestionnaireInterval-modal {
  min-width: 880px;
}

.client-questionnaires-modal {
  max-width: 900px;
}

.add-serviceCollaborator-modal {
  img {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .main-panel {
    width: 100%;
  }
}

.questionnaire-modal {
  max-width: 1000px;
}

.selectBaselineDateModal {
  max-width: 600px;
}

.password {
  & .form-control {
    display: inline;
  }

  & .plaintext-toggle {
    margin-left: -25px;
  }
}

.pagination-button {
  border-radius: 100px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.ml-140 {
  margin-left: 140px;
}

.rc-slider{
  height:40px !important;
  display: flex;
  align-items: center;
}

.rc-slider-disabled {
  background-color: #FFFFFF !important;
}

.serviceUserQuestionnaires-table{
  tbody tr td{
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.confirm-modal-alt-style{
  margin-top:-250px !important;

  p{
    font-size:14px;
    text-align: left;
    color: black;
    font-weight:400;
  }
  li{
    font-size:14px;
    text-align: left;
    color:black;
    font-weight:400;
  }

  h2{
    font-size:24px;
    font-weight:600;
    margin-bottom:15px;
  }
}

@media screen and (max-width: 667px) {
  .registration-container{

    h4{
      margin-top: 10px;
      font-size:20px;
    }
    h2{
      font-size: 20px;
      font-weight: 600;
    }

    label{
    }
  }

  .registration-card{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom:20px !important;
  }
}


