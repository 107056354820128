.joinpage{
  background-color:#f6f0e0;
  min-height:100vh;
  padding-top:100px;

  span{
    font-size:16px;
  }

  .header1{
    font-size:18px;
    font-weight:700
  }
  
  .header2{
    font-size:14px;
    font-weight:400;
  }

  .form-check-label {
    display: inline-block;
    margin-top: 20px;

    .form-check-input {
      margin-left: 0;
      position: relative;
    }
  }
}

.join-page-card{
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.30);
  border-radius: 13px;
  padding:40px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  max-width: 700px;

  h6 {
    margin-top: 40px;
  }

  button {
    margin-top: 30px;
  }
}

.join-page-button{
  background-color:$xamk-yellow;
  color:black;
  text-transform: none;
  border-radius:13px;
  font-size:14px;
  padding:21px 25px;
  margin-top: 30px;
}


.join-page-success-text{
  font-size:18px;
}

@media screen and (max-width: 800px) {
  .joinpage{
    padding-top:0px;
    background-color:white;
    .container{
      padding:0px;
    }
  }

  .join-page-success-text {
    padding:0;
  }

  .join-page-card{
    box-shadow: none;
    padding:15px;
    min-height:350px;
  }
}
