@import '../variables';

.wrapper-clientdashboard {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center, center;
  display: flex;
  flex-direction: column;
}

.client-view {
  margin-right: auto;
  margin-left: auto;
  padding: 0px;

  a:link {
    text-decoration: none
  }

  a:visited {
    text-decoration: none
  }

  a:hover {
    text-decoration: none
  }

  a:active {
    text-decoration: none
  }

  a {
    color: #007bff;
  }

  .text-danger {
    color: #ff4d4f !important;
  }

  .text-success {
    color: #5BAB0B !important;
  }

  button {
    border-radius: 9px;
  }

  .btn-primary {
    color: white;
    background-color: black;

    &:hover {
      background-color: $xamk-yellow;
      color: white;
    }
  }

  .btn-info {
    color: white;
    background-color: black;

    &:hover {
      background-color: $xamk-yellow;
      color: white;
    }
  }

  .card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 11px;
    border: 1px solid #bfbfbf;
  }

  .clickable-card {
    cursor: pointer;

    .text-muted {
      font-size: 14px;
      font-weight: 500;
    }

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .clickable-card:hover {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px 0px;
  }

  .category-icon {
    width: 45px;
  }
}

.prev-icon-button {
  width: 45px;
}

.client-badge {
  background-color: $xamk-yellow;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-left: 0.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.otheranswers-text {
  margin-top: -15px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.allanswered-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

.goodjob-text {
  font-size: 20px;
  font-weight: 700;
}

.allanswered-text {
  font-size: 16px;
  font-weight: 500;
}

.hyvio-rectangle {
  width: 100px;
  margin-right: 20px;
}

.clientdash-answerQuestionanireButton {
  margin-right: 100px;
}

@media screen and (max-width: 576px) {
  .wrapper-clientdashboard {
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .allanswered-container {
    margin-left: 0;
  }

  .hyvio-rectangle {
    margin-right: 15px;
  }

  .clientdash-answerQuestionanireButton {
    margin-right: 0px;
  }
}