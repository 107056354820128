.question-report-container{
  flex-direction: column-reverse;
}

@media screen and (min-width: 700px) {
  .question-report-container{
    flex-direction: row;
  }

  .question-report-change-container{
    min-width: 125px;
  }
}