.service-collaborator-example-container{
  border: 1px solid #c8c8c8;
  padding:10px;
  padding-bottom:20px;
  border-radius:8px;
}

.example-button{
  background-color: $xamk-yellow;
  padding: 15px 23px;
  border-radius:30px;
  font-size:1rem;
  font-weight:600;
  text-transform: uppercase;
}

.collaborators-box{
  border:1px solid #c3c3c3;
  padding-left:20px;
  padding-right:20px;
  padding-top:25px;
  padding-bottom:25px;
  margin-bottom:10px;
  border-radius:8px;
}

.service-collaborator-example-card{
  h3{
    font-weight:400 !important;
    margin-bottom:5px !important;
  }
  h4{
    font-size:34px !important;
    font-weight:400 !important;
    text-align: center;
    text-decoration: underline solid #FBBA18 0.44rem;
    text-underline-offset: -0.1rem;
    text-decoration-skip-ink: none;
    margin-bottom:25px;
  }

  h6{
    text-transform: none;
    font-size:18px;
  }

  p{
    font-size:16px;
  }
}