@import '../variables';

.analytic-card {
    h6 {
        text-transform: none;
        font-weight: 500;
        margin-bottom: 0.2rem;
    }

    h5 {
        font-size: 17px;
        margin-bottom: 0px;
        font-weight: 300;
    }
}

.analytics-card-group {
    padding: 10px;

    h6 {
        text-transform: none;
        font-weight: 500;
        margin-bottom: 0.2rem;
    }

    p {
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        margin-bottom: 0.2rem;
    }
}

.analytics-card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.analytic-card-chart-container {
    display: flex;
    height: 73px;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.analytic-card-content-container-with-result {
    display: flex;
    flex-direction: row-reverse;
}

.analytics-title-group {
    margin-left: 0.2rem;

    h5 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
    }

    p {
        font-size: 16px;
        font-weight: 500;
    }
}

.analytics-answers-title {
    font-size: 22px;
    text-transform: none;
    font-weight: 600;
}

.check-results-text {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
}

.analytics-no-information-text {
    font-size: 16px;
    text-align: center;
}

.client-nav.nav-pills:not(.flex-column) .nav-item:first-child .nav-link {
    border-radius: 10px 0 0 10px !important;
}

.client-nav.nav-pills:not(.flex-column) .nav-item:last-child .nav-link {
    border-radius: 0 10px 10px 0 !important;
}

.client-nav.nav-pills:not(.flex-column) .nav-item:only-child .nav-link {
    border-radius: 10px 10px 10px 10px !important;
    cursor: default;
}

.total-result-change {
    display: flex;
    align-items: center;
    justify-content: center;

    span:not(.client-value-change-unit) {
        font-size: 22px !important;
        font-weight: 700 !important;
    }

    i {
        font-size: 20px;
    }
}

.infobox-scoreunit {
    font-size: 15px;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .client-analytics-basedataAndChart-container {
        flex-direction: column-reverse;
    }

    .analytics-card-group {
        display: flex;
        align-items: center;
        flex-direction: column;

        h6 {
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
        }
    }

    .analytics-card-container {
        flex-direction: row;
    }

    .analytic-card-content-container-with-result {
        display: flex;
        flex-direction: column;
    }

    .analytic-card-chart-container {
        margin-left: 0 !important;
    }
}