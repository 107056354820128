.questionnaireForm-button {
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
}

.next-and-submit-button-alignmentFix {
  margin-right: 5px;
}

.question-group-card {
  box-shadow: 0 0 0 0;

  .card-body {
    padding: 0px;
  }

  .card-header {
    font-size: 20px;
    font-weight: 700;
    padding-left: 0;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .form-check-radio {
    margin-bottom: 8px;
  }

  label {
    font-size: 14px;
    font-weight: 600
  }

  strong {
    font-weight: 700
  }

  small {
    font-size: 90%;
    font-weight: 500
  }

  h5 {
    font-weight: 500;
    font-size: 1.45em;
  }

  .form-control[disabled] {
    background-color: white;
  }
}

.question-container {
  margin-bottom: 15px;
}

.slider-group {
  .slider-row {
    flex: auto;
    width: auto;
    flex-grow: 1;
  }

  .slider-clear-button-row {
    flex: unset;
    margin: 0;
    padding: 0;
    width: 25px;

    .col {
      margin: 0;
      padding: 0;
      width: auto;

      button {
        margin: auto;
      }
    }
  }

  margin-top: 5px;
  margin-bottom: 30px;
}

.radio-group {
  margin-top: 5px;
  margin-left: 10px;

  .form-check-label {
    font-size: 14px;
    font-weight: 400;
  }
}

.confirmAnswers-group {
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
  }

  span {
    font-size: 14px;
  }

  strong {
    font-size: 14px;
    font-weight: 600;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.confirmrow-group {
  margin-bottom: 15px;

  b {
    font-size: 18px;
  }
}

.question-group-description {
  font-size: 14px;
  font-weight: 400;
  margin-top: -10px;
}

.question-description {
  font-size: 13px;
  margin-top: -2px;
  margin-bottom: 12px;
}

.container-results {
  h3 {
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h4 {
    font-weight: 500;
    text-decoration: underline;
    margin-top: 20px;
  }

  .card {
    box-shadow: 0 0 0 0 !important;
    border: 0 !important;

    b {
      font-size: 16px;
    }
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 0;
  }

  .card-subtitle {
    margin-top: 5px;
    margin-left: 7px;

    label {
      font-size: 14px;
    }
  }

  .result-icon {
    margin-top: 22px;
    font-size: 32px;
    margin-left: 20px;
  }
}

.result-cardBody {
  padding: 0 !important;
}

.questionRow-container {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.numberfield-container {
  width: 250px;
}

.radio-field-label {
  font-weight: 400 !important;
}

.checkboxfield-label {
  font-weight: 400 !important;
}

.textfield-container {
  max-width: 650px;
}

.minmaxdescription-container {
  label {
    font-weight: 400;
  }
}

.questionValueHeaders-container {
  label {
    font-weight: 400;
  }
}


.questionValueHeaders-count-2 {
  .questionValueHeaders-col-0 {
    max-width: 72% !important;
    width: 72% !important;
  }

  .questionValueHeaders-col-1 {
    max-width: 28% !important;
    width: 28% !important;
  }
}

@media screen and (max-width: 991px) {
  .questionValueHeaders-count-2 {
    flex-direction: column;

    .col {
      max-width: 100% !important;
      width: 100% !important;
    }

    .questionValueHeaders-container {
      margin-bottom: 0px !important;
    }
  }
}