.footer .logo img {
  height: 40px;
  margin-left: 30px;
}

.footer.footer-client{
  background-color: #fff3d5;
}

.footer-client{
  margin-top:auto;

  b{
    color:black;
  }
  a{
    color:black;
  }
}