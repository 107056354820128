.question-table-container{
  display:flex;
  flex-direction: column;
  gap:30px;

  .count-text{
    padding-left:5px;
    font-size:13px;
  }
}

.multichoice-table{
  tbody{
    border-bottom:6px solid #f4f7fc;
  }

  tbody:last-child{
    border-bottom:0;
  }

  .questionChoice-cell{
    border-left:1px solid #dee2e6;
  }

  tr{
    th{
      text-transform: uppercase !important;
      background-color:#f4f7fc !important;
      color:rgb(101, 101, 101);
      font-size:14px !important;
      font-weight:600 !important;
    }
  }

  thead{
    text-transform: uppercase !important;
    background-color:#f4f7fc !important;
    color:rgb(128, 128, 128);
  }

  th{
    font-size:14px !important;
    font-weight:600 !important;
    line-height:1.4em !important;
  }

  th:first-child{
    padding-left:20px !important;
  }

  th:last-child{
    padding-right:20px !important;
  }

  td:first-child{
    padding-left:20px !important;
  }

  td:last-child{
    padding-right:20px !important;
  }

  th{
    align-self: center;
  }
}